export default [
  {
    id:'analytics',
    name: `cookies.analytical.title`,
    description: `cookies.analytical.description`,
    cookies: [
      {  
        name: '_ga',
        holder: 'Google Analytics',
        expiration: [`time.years`, 2],
        description: `cookies.analytical._ga`
      },      
      {  
        name: '_gat',
        holder: 'Google Analytics',
        expiration: [`time.minute`, 1],
        description: `cookies.analytical._ga`
      },      
      {  
        name: '_gid',
        holder: 'Google Analytics',
        expiration: [`time.hours`, 24],
        description: `cookies.analytical._gid`
      }
    ]
  }
]