import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"

export default css`
  position: relative;
  display: flex;
  flex-direction: column;
  ul {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    li {
      margin-bottom: 0;
      padding-left: ${rhythm(0.7)};
      .previous-menu-item {
        margin-right: ${rhythm(0.5)};
      }
      .following-menu-item {
        margin-left: ${rhythm(0.5)};
      }
      .previous-menu-item:empty, .following-menu-item:empty { 
        margin: 0;
      }
    }
  }
  .menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: none;
    justify-content: center;
    align-items: center;
    &.menu-backdrop__open {
      display: flex;
      flex-direction: column;
    }
    ul {
      flex-direction: column;
    }
  }
  .secondary-menu {
    padding-left: ${rhythm(1)}; 
    padding-right: ${rhythm(1)};
    position: absolute;
    top: 3.5rem;
    width: calc(100% + ${rhythm(1)});
    background-color: white;
    height: ${rhythm(2.5)};
    &:empty{
      height: 0;
    }
  }
  .rc-slider {
    margin-top: ${rhythm(0.5)};
    .rc-slider-mark-text {
      line-height: ${rhythm(0.5)};
    }
  }
  &.compactView {
    .menu-backdrop { 
      justify-content: space-around;
    }
    .secondary-menu {
      width 50%;
      position: relative;
      background-color: transparent; 
      &:empty{
        height: ${rhythm(2.5)};
      }       
    }
    .rc-slider {
      width: 150%;
      left: -25%;
    }
  }
`
