import React from "react"
// eslint-disable-next-line
import { jsx } from '@emotion/core'
import { rhythm, scale } from "../utils/typography"
import CookiesDialog from './cookiesDialog'
import { LocaleProvider } from '../utils/localeProvider'
import root from '../utils/root'
import Logo from './logo'
import Menu from './menu'
import Footer from './footer'

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.mainRef = React.createRef();
  }
  componentDidMount(){
    root.requestAnimationFrame(() => {
      this.mainRef.current.style.opacity = 1
    })
  }
  render() {
    const { customMenuItems, location, locale, title, children } = this.props
    return (
      <LocaleProvider locale={locale}>
        <header
            css={{
              ...scale(1),
              position: 'sticky',
              display: 'flex',
              width: '100%',
              top: 0,
              left: 0,
              padding: `${rhythm(1)} ${rhythm(1.9)}`,
              backgroundColor: 'white',
              zIndex: 1,
              justifyContent: 'space-between'
            }}
          >
            <Logo
              {...{
                location,
                title
              }}
            />
            <Menu customMenuItems={customMenuItems}/>
          </header>
        <div
          css={{
            marginLeft: `auto`,
            marginRight: `auto`,
            padding: `0 ${rhythm(1.7)}`,
            minHeight: `calc(100vh - 180px)`
          }}
        >  
        <main
            css={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              transition: `opacity 500ms ease-in-out`,
              opacity: 0
            }}
            ref={this.mainRef}
          >
            {children}
          </main>
        </div>
        <CookiesDialog />
        <Footer/>
      </LocaleProvider>
    )
  }
}

export default Layout
