import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"

export default css`
  color: inherit;
  font-family: Heebo, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-size: ${rhythm(0.8)};
  box-shadow: none;
  font-weight: 100;
  cursor: pointer;
  &:hover,
  &.active {
    font-weight: 300;
  }
  &.breadcrumb {
    font-size: ${rhythm(0.6)};
    &:not(:first-of-type):before {
      content: "/";
    }
  }
`
