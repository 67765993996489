import React from "react"

const IconViewer = props => {
  const {
    alt,
    height,
    width,
    icon,
  } = props
  return (
    <img 
      alt={alt}
      src={icon}
      css={{
        height,
        width
      }}
      {...props}
    />
  )
}

export default IconViewer