import Typography from "typography"
import judahTheme from 'typography-theme-judah'

judahTheme.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    h2:{
      fontWeight: 400
    }
    }
}

judahTheme.headerFontFamily = ['Heebo', 'sans-serif'];
judahTheme.bodyFontFamily = ['Heebo', 'sans-serif'];
judahTheme.googleFonts = [];
judahTheme.baseFontSize = "16px";
judahTheme.bodyWeight = 200;
judahTheme.boldWeight = 400;

const typography = new Typography(judahTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
