import root from "./root"
import { useStaticQuery, graphql } from "gatsby"

const defaultLanguage = "es"

const guessLanguage = () => {
  const { location: { pathname = "/" } = {} } = root
  const regexp = new RegExp(`/(?:([a-z]{2})/)?(?:[a-zA-Z0-9-_]+/)?`)
  const [, locale = defaultLanguage] = pathname.match(regexp)

  return locale
}

const useLocalize = language => {
  const { allConfigJson } = useStaticQuery(
    graphql`
      query {
        allConfigJson(filter: { id: { regex: "/literals/" } }) {
          edges {
            node {
              language
              literals {
                literal
                translation
              }
            }
          }
        }
      }
    `
  )
  const resources = allConfigJson.edges.reduce(
    (acc, { node: { language, literals } }) => {
      acc[language] = literals.reduce((acc, { literal, translation }) => {
        acc[literal] = translation
        return acc
      }, {})
      return acc
    },
    {}
  )
  const messages = resources[language || guessLanguage()]
  return (...args) => {
    const [messageKey, ...data] = Array.isArray(args[0])
      ? [...args[0]]
      : [...args]
    const [namedData] = [...data]
    const message = messages[messageKey]

    if (!message) {
      return messageKey
    }

    const parsedMessage = message.replace(
      /\$\{(\d+)\}/gi,
      (_, group) => "${arguments[" + group + "]}"
    )

    return new Function("return `" + parsedMessage + "`;").apply(
      namedData,
      data
    )
  }
}

const useLocalizeUrl = (language = guessLanguage()) => path =>
  language === defaultLanguage ? path : `/${language}${path}`

export { guessLanguage, useLocalize, useLocalizeUrl }
