import Icon from "./icon"

import arrowLeft from "./arrowLeft.svg"
import arrowRight from "./arrowRight.svg"
import arrow from "./arrow.svg"
import facebook from "./facebook.svg"
import instagram from "./instagram.svg"
import linkedin from "./linkedin.svg"
import pinterest from "./pinterest.svg"
import twitter from "./twitter.svg"
import vimeo from "./vimeo.svg"
import behance from "./behance.svg"
import domestika from "./domestika.svg"
import filter from "./filter.svg"


export default Icon

export {
  arrowLeft,
  arrowRight,
  arrow,
  facebook,
  instagram,
  linkedin,
  pinterest,
  twitter,
  vimeo,
  behance,
  domestika,
  filter,
}
