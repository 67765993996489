import React, { useState }  from "react"
import useViewportSize, { SIZES } from "../utils/hooks/useViewportSize";
import { useLocale } from '../utils/localeProvider';
import { useLocalize, useLocalizeUrl } from '../utils/i18n';
import MenuButton from './menuButton';
import MenuLanguageButton from './menuLanguageToggle';
import Link from './link'
import css from './menu.css'

const SECONDARY_MENU = 'secondary-menu';

const Menu = ({customMenuItems = []}) => {
  const [isOpen, setIsOpen] = useState()
  const viewportSize = useViewportSize()
  const isCompactView = [SIZES.XXS, SIZES.XS].includes(viewportSize)
  const locale = useLocale();
  const localize = useLocalize(locale);
  const localizeUrl = useLocalizeUrl(locale);
  const menuItems = [
    {
      id: 'drawings',
      label: localize('menu.drawings'),
      to: localizeUrl('/')
    },
    {
      id: 'about',
      label: localize('menu.about'),
      to: localizeUrl('/about')
    },
    {
      id: 'contact',
      label: localize('menu.contact'),
      to: localizeUrl('/contact')
    },
    {	
      id: 'nft',	
      label: localize('menu.nft'),	
      to:  localizeUrl('/nft')
    }
  ]

  if(!isCompactView && isOpen) {
    setIsOpen(!isOpen)
  }

  const menuItemList = (
    <ul>
      {menuItems.map(({id, label, to}) => {
        const { component: previousCustomItem } = customMenuItems.find(({prependTo}) => prependTo === id) || {};
        const { component: followingCustomItem } = customMenuItems.find(({appendTo}) => appendTo === id) || {};
        return (
          <li key={to} >
            <span className="previous-menu-item">
              { previousCustomItem ? previousCustomItem : null}
            </span>
            <Link.Styled 
              activeClassName='active'
              to={to} 
              children={label}/>
            <span className="following-menu-item">
              { followingCustomItem ? followingCustomItem : null} 
            </span>
          </li>
        )
      })}
      <li key='languageToggle' >
        <MenuLanguageButton/>
      </li>
    </ul>
  )

  const secondaryMenuPreviousItems = customMenuItems
    .filter(({prependTo}) => prependTo === SECONDARY_MENU)
  const secondaryMenuFollowingItems = customMenuItems
    .filter(({appendTo}) => appendTo === SECONDARY_MENU)
  const secondaryMenuItemList = secondaryMenuPreviousItems.concat(secondaryMenuFollowingItems)
    .map(({component}) => component)
  
  const secondaryMenu = (
    <div className={'secondary-menu'}>
      {secondaryMenuItemList ? secondaryMenuItemList : null }  
    </div>
  );


  return (
    <div
      css={css}
      className={`${isCompactView ? 'compactView' : ''}`}
    >
      {
        isCompactView 
          ? (<>
              <MenuButton
                {...{
                  isOpen,
                  onClick: () => { setIsOpen(!isOpen) }
                }}
              />
              <div 
                className={ 
                  `menu-backdrop ${isOpen ? 'menu-backdrop__open' : ''}`
                }
              >
                {menuItemList}
                {secondaryMenu}
              </div>
            </>) 
          : (<>
              {menuItemList}
              {secondaryMenu}
            </>)
      }
    </div>
  )
}

export default Menu
