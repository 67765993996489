import React from 'react'
import Link from './link'
import { useStaticQuery, graphql } from "gatsby"

import { useLocale } from '../utils/localeProvider';
import { useLocalize, useLocalizeUrl } from '../utils/i18n';

import css from'./footer.css';

const Footer = () => {
  const { allConfigJson } = useStaticQuery(
    graphql`
      query {
        allConfigJson (
          filter: { id: { eq: "footer-config" } }
        ) {
          edges {
            node {
              show_footer
              footer_menu {
                label
                link
              }
            } 
          }
        }
      }
    `
  );
  const locale = useLocale();
  const localize = useLocalize(locale);
  const localizeUrl = useLocalizeUrl(locale);
  const { footer_menu, show_footer } = allConfigJson.edges[0].node;
  const menu = footer_menu.map(({
      label, 
      link
    }) => (<Link.Styled to={localizeUrl(link)} children={localize(label)}/>)
  );

  return show_footer
    ? (
      <footer css={css}>
        {menu}
      </footer>
    )
    : null;
}

export default Footer

