import React from "react"
import { scale } from "../utils/typography"
import { useLocale } from '../utils/localeProvider'
import { useLocalizeUrl } from '../utils/i18n'
import Link from "./link"

const Logo = ({
  location = `${__PATH_PREFIX__}/`,
  title
}) => {
  const locale = useLocale()
  const localizeUrl = useLocalizeUrl(locale)
  const rootPath = `${__PATH_PREFIX__}/`
  const HeaderTag =  (location.pathname === rootPath) ? 'h1' : 'h3'

  return (
    <HeaderTag css={{...scale(1),margin: 0}}>
      <Link.Styled to={localizeUrl(`/`)} label={title}/>
    </HeaderTag>
  )
}

export default Logo
