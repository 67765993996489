import React, { useState } from "react"
import Icon, { arrow } from "../icons"
import Link from "./link"
import analytics from "../utils/analytics"
import { useLocale } from '../utils/localeProvider';
import { useLocalize, useLocalizeUrl } from '../utils/i18n';
import root from '../utils/root';
import cookieCss from "./cookiesDialog.css"
import cookieData from "./cookiesDialog.data"

const COOKIES_DISCLAIMER_URL = '/politica-de-cookies'
const COOKIES_DIALOG_COOKIE = `cookies-dialog-shown`
const HIDE = false
const SHOW = true
const DEFAULT_CHECKS_VALUE = false

const CookiesSection = ({id, name, description, cookies, onChange, checked}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const locale = useLocale();
  const localize = useLocalize(locale);

  return (
    <div 
      key={id}
      className={isCollapsed ? 'isCollapsed' : ''}
    >
      <label>
        { (id !== 'technical')
            ? <input type='checkbox' {...{onChange, checked}} />
            : null
        }&nbsp;{localize(name)}
      </label>
      <Icon {...{
        height: 20,
        icon: arrow,
        alt: 'collapse',
        onClick: () => { 
          setIsCollapsed(!isCollapsed)
        }
      }} />
      <p>{localize(description)}</p>
      <div>
        <table>
          <thead>
            <tr>
              <th>{localize(`cookies.dialog.table.name`)}</th>
              <th>{localize(`cookies.dialog.table.holder`)}</th>
              <th>{localize(`cookies.dialog.table.duration`)}</th>
              <th>{localize(`cookies.dialog.table.purpose`)}</th>
            </tr>
          </thead>
          <tbody>
            {
              cookies
                .map(({name, holder, expiration, description}) => (
                <tr key={name}>
                  <td>{localize(name)}</td>
                  <td>{localize(holder)}</td>
                  <td>{localize(expiration)}</td>
                  <td>{localize(description)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
)}


const CookiesDialog = () => {
  const [showCustomization, setShowCustomization] = useState(HIDE)
  const [showCookiesDialog, setShowCookiesDialog] = useState(
    !(root.sessionStorage && root.sessionStorage.getItem(COOKIES_DIALOG_COOKIE) === `${SHOW}`)
  )
  const [cookiesConfiguration, setCookiesConfiguration] = useState(
    cookieData.reduce((acc, {id}) => (acc[id] = DEFAULT_CHECKS_VALUE) && acc, {})
  )
  const locale = useLocale();
  const localize = useLocalize(locale);
  const localizeUrl = useLocalizeUrl(locale);

  return showCookiesDialog 
    ? (
      <div css={cookieCss}>
        <p>
          <span role="img" aria-label="cookie">🍪</span> {localize(`cookies.dialog.text`)}
        </p>
        { 
          showCustomization 
            ? (
              <div>
                <ul>
                  { cookieData.map(({id, name, description, cookies}) => (
                    <li key={id}>
                      <CookiesSection 
                        {...{
                          id,
                          name,
                          description,
                          cookies,
                          checked: cookiesConfiguration[id],
                          onChange: () => {
                            const newState = {...cookiesConfiguration}
                            newState[id] = !cookiesConfiguration[id]
                            setCookiesConfiguration(newState)
                          }
                        }} 
                      />
                    </li>
                  )) }
                </ul>
              </div>
            ) 
            : null
          }
        { 
          showCustomization 
            ? (<Link.Styled 
                label={localize(`cookies.dialog.button.more`)}
                to={localizeUrl(COOKIES_DISCLAIMER_URL)}
              />)
            : (<Link.Styled 
                label={localize(`cookies.dialog.button.config`)}
                onClick={_=> {
                  setShowCustomization(SHOW)
                }}
              />)
          }

        <Link.Styled
          label={localize(`cookies.dialog.button.accept`)}
          onClick={_ => {
            root.sessionStorage && root.sessionStorage.setItem(COOKIES_DIALOG_COOKIE, `${SHOW}`)
            analytics.GAOptIn(cookiesConfiguration.analytics)
            setShowCookiesDialog(HIDE)
          }}
        />
      </div>
    )
    : null
}

export default CookiesDialog
