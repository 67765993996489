import React, {createContext, useContext} from 'react';

export const LocaleContext = createContext();

export const LocaleProvider = ({locale, children}) =>(
  <LocaleContext.Provider value={locale}>
    {children}
  </LocaleContext.Provider>
);

export const useLocale = () => useContext(LocaleContext);