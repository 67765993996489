import React from 'react'
import { css } from '@emotion/core'

const buttonStyle = css`
  display: inline-block;
  z-index: 1;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  span {
    width: 35px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.2s;
    display: block;
  }
  &.isOpen span:nth-child(1){
    -webkit-transform: rotate(-45deg) translate(-8px, 7px);
    transform: rotate(-45deg) translate(-8px, 7px);
  }
  &.isOpen span:nth-child(2){
    opacity: 0;
  }
  &.isOpen span:nth-child(3){
    -webkit-transform: rotate(45deg) translate(-5px, -5px);
    transform: rotate(45deg) translate(-5px, -5px);
  }
`

const MenuButton = ({isOpen, onClick}) => (
  <span 
    css={buttonStyle} 
    className={isOpen ? 'isOpen' : ''}
    onClick={onClick}
    role='button'
  >
    <span></span>
    <span></span>
    <span></span>
  </span>
)

export default MenuButton