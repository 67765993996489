import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"

export default css`
  display: flex;
  justify-content: center;
  padding: ${rhythm(1)};
  a {
    font-size: ${rhythm(1/2)};
    margin: 0 ${rhythm(1/2)};
  }
`
