import React from "react"

import { useLocale } from "../utils/localeProvider"
import { useLocalizeUrl } from "../utils/i18n"
import root from "../utils/root"
import Link from "./link"

function MenuLanguageButton () {
  const locale = useLocale()
  const { location: { pathname = "" } = {} } = root
  const path = pathname.replace(`${locale}/`, "")

  return (
    <>
      <Link.Styled
        className={[`breadcrumb`, locale === 'es' ? `active` : ``].join(
          " "
        )}
        label={'es'}
        key={'es'}
        to={useLocalizeUrl('es')(path)}
      />
      <Link.Styled
        className={[`breadcrumb`, locale === 'en' ? `active` : ``].join(
          " "
        )}
        label={'en'}
        key={'en'}
        to={useLocalizeUrl('en')(path)}
      />
      
    </>
  )
}


export default MenuLanguageButton
