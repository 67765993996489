import React, { Component } from "react"
import { Link } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import css from './link.css'

const BaseLink = props => {
  const { to, label, children, tag } = props
  const external = !(new RegExp(/^(?:\/[a-z0-9-_]*)+$/).test(to))
  let defaultTag = Link

  if(external) {
    defaultTag = OutboundLink
  }

  if (!to) {
    defaultTag = 'span'
  }

  const Tag = tag || defaultTag

  return (
    <Tag 
      {...props}
      {...(to && external) ? { href:to, target:'_blank', rel:'noopener noreferrer' } : null }
    >
      {label || children}
    </Tag>
  )
}

export default class extends Component {
  static Styled = props => (<BaseLink {...props} css={css} />)
  
  render () {
    return (<BaseLink {...this.props}/>)
  }
}