import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"

export default css`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #cdcdcd;
  padding: ${rhythm(1)};
  max-height: 100vh;
  z-index: 1;
  overflow: auto;
  p{
    font-size: ${rhythm(1 / 2)};
  }
  li {
    list-style: none;
    &> div {
      border-bottom: 1px solid black;
      div {
        height: auto;
        overflow: hidden;
      }
      table {
        font-size: ${rhythm(1 / 2)};
        td {
          padding: ${rhythm(1 / 3)};
        }
      }
      p {    
        margin-bottom: ${rhythm(1 / 2)};
      }
      img {
        margin-bottom: 0;
        cursor: pointer;
        float: right;
        transform: rotate(180deg);
      }
      &.isCollapsed {
        img {
          transform: rotate(0deg);
        }
        div{
          height: 0;
        }
      }
    }
  }
  & > span, & > a {
    margin-left: ${rhythm(1)};
    font-size: ${rhythm(0.7)};
    &:last-child {
      float: right;
      margin-right: ${rhythm(1)};
    }
  }
`
